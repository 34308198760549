import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const HomePage = lazy(() => import('./pages/home'));
const LoginPage = lazy(() => import('./pages/login.page'));
const UsersPage = lazy(() => import('./pages/users'));
const VideosPage = lazy(() => import('./pages/videos'));
const NotFoundPage = lazy(() => import('./pages/notfound.page'));
const WatchLogsPage = lazy(() => import('./pages/watch-logs'));
const DistrictsPage = lazy(() => import('./pages/districts'));
const QRCountsPage = lazy(() => import('./pages/qr-counts'));
const CustomersPage = lazy(() => import('./pages/customers'));
const InvoicesPage = lazy(() => import('./pages/invoices'));

function App() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="users" element={<UsersPage />} />
      <Route path="videos" element={<VideosPage />} />
      <Route path="watch-logs" element={<WatchLogsPage />} />
      <Route path="districts" element={<DistrictsPage />} />
      <Route path="qr-counts" element={<QRCountsPage />} />
      <Route path="customers" element={<CustomersPage />} />
      <Route path="invoices" element={<InvoicesPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
