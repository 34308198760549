import { configureStore } from '@reduxjs/toolkit';
import invoicesSlice from './features/invoices.slice';
import userSlice from './features/user.slice';

const store = configureStore({
  reducer: {
    user: userSlice,
    invoices: invoicesSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
