import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceModel } from '../../models/invoice.model';
import { RootState } from '../store';

const initialState: {
  value: InvoiceModel[];
  loading: boolean;
} = { value: [], loading: false };

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoices: (state, action: PayloadAction<InvoiceModel[]>) => {
      state.value = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const invoicesActions = invoicesSlice.actions;
export const selectInvoices = (state: RootState) => state.invoices.value;

export default invoicesSlice.reducer;
