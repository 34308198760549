import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from '../../models/user.model';
import { RoleEnum } from '../../models/role.enum';
import { RootState } from '../store';

const initialState: {
  value: UserModel;
} = {
  value: {
    _id: '',
    name: '',
    email: '',
    role: RoleEnum.Worker,
    phone: '',
    is_blocked: false,
    created_at: '',
    updated_at: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.value = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.value;

export default userSlice.reducer;
