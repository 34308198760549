import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, App as AntdApp } from 'antd';
import mn_MN from 'antd/locale/mn_MN';
import LoadingScreen from './components/LoadingScreen';
import { Provider } from 'react-redux';
import store from './redux/store';
import { NextUIProvider } from '@nextui-org/react';
import ErrorBoundary from './components/ErrorBoundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <React.StrictMode>
        <NextUIProvider>
          <AntdApp>
            <ConfigProvider
              locale={mn_MN}
              theme={{
                token: {
                  colorPrimary: '#1463F3',
                },
              }}
            >
              <Suspense fallback={<LoadingScreen />}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </Suspense>
            </ConfigProvider>
          </AntdApp>
        </NextUIProvider>
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>,
);
